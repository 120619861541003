import './ContactPage.css';
import headshot from '../assets/headshot.png'

const ContactPage = () => {

  return (
    <div className="faq-root">
      <iframe frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" title="Bathroom Abstract Painted Wall Mural with Golden Shine" width="416px" height="383px" src="https://www.youtube.com/embed/imd6UWhzMBI?autoplay=0&mute=0&controls=1&loop=0&origin=https%3A%2F%2Fwww.copeleydesigns.com&playsinline=1&enablejsapi=1&widgetid=3"></iframe>
      <p>
        Welcome to a world of bold and gold. I’m Kelly Aiken, an artist passionate about creating original works, gilding with gold leaf, and bringing fresh art into new homes. Inspired by my daughter, Copeley, my creations are characterized by vibrant colors, high energy, and dynamic movement. Having moved frequently as the wife of a retired NFL player, I understand the significance of making each space feel like home. My art aims to provide comfort and infuse a timeless yet bold aesthetic into every environment, making spaces #abitmorebeautiful. My commitment extends beyond art. I support mental health awareness by donating a portion of proceeds to
        <p>
          <span className="charity">The Brain & Behavior Research Foundation</span>.
        </p>
      </p>
      <p>
        For commissions or inquiries about my shop, please reach out to me at <a href="mailto:copeleydesigns@gmail.com">copeleydesigns@gmail.com</a>. My studio is located in the suburbs of Charlotte, NC.
      </p>
      <div>
        <img className="contact-img" alt="Kelly Aiken" src={headshot} />
        <div className="artist-credit">
          <span className="artist-name">Kelly Aiken</span><br />Head Artist
        </div>
      </div>
      <p>
        Kelly Aiken is a 30 something old artist and creative designer living the south Charlotte, NC area. Ms. Aiken graduated from the University of Virginia in 2012 with a Bachelor of Science in Architecture Design. After working for an architecture firm as Marketing Director in Boston, MA, Kelly switched fields into the art world as an Assistant Creative Director at The Paint Bar. She lead studio instruction to customers, created new calendar content for two brick and mortar locations, and recruited artistic talent by hiring new team members to ensure operational success.
      </p>
      <p>
        In November 2016, Kelly’s daughter, Copeley, inspired her to launch her own creative collections. Copeley Designs Art was established in February 2018, featuring original abstract gold leaf art displayed in local galleries, boutiques, and through West Elm/Minted. Kelly also teaches gold leaf abstract art and the Charlotte Skyline through Skillpop Charlotte. In 2021, she expanded her practice to include a growing residential and commercial mural branch of CD Art.
      </p>
    </div>
  );
}

export default ContactPage;
