import './TermsPage.css';

const TermsPage = () => {

  return (
    <div className="terms-root">
      <h2>Terms of Use </h2>
      <p>
        <span>1. Acceptance of Terms</span><br />
        By accessing and using copeleydesigns.com, you agree to comply with and be bound by the following Terms of Use. If you do not agree with these terms, please do not use this website.
      </p>
      <p>
        <span>2. Intellectual Property</span><br />
        All content on this website, including but not limited to text, images, designs, and artwork, is the property of Copeley Designs LLC and is protected by copyright, trademark, and other intellectual property laws. Unauthorized use of any content from this website is prohibited.
      </p>
      <p>
        <span>3. Use of Website</span><br />
        You may use this website for personal, non-commercial purposes only. You agree not to:
        <ul>
          <li>Reproduce, modify, distribute, or display any part of this website without prior written permission.</li>
          <li>Use this website for any unlawful purpose or in any manner that could damage, disable, overburden, or impair the website or interfere with any other party’s use of the website.</li>
        </ul>
      </p>
      <p>
        <span>4. User Content</span><br />
        Any content you submit to this website, including feedback, comments, or suggestions, becomes the property of Copeley Designs LLC. You grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, and publish such content.
      </p>
      <p>
        <span>5. Limitation of Liability</span><br />
        Copeley Designs LLC is not liable for any damages or losses arising from the use or inability to use this website. This includes, but is not limited to, direct, indirect, incidental, or consequential damages.
      </p>
      <p>
        <span>6. Links to Other Websites</span><br />
        This website may contain links to third-party websites. These links are provided for your convenience. Copeley Designs LLC does not endorse or take responsibility for the content of any linked third-party sites.
      </p>
      <p>
        <span>7. Governing Law</span><br />
        These Terms of Use are governed by and construed in accordance with the laws of The United States of America, and South Carolina. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts of South Carolina.
      </p>
    </div>
  );
}

export default TermsPage;
