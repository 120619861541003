import './FAQPage.css';
import faq from '../assets/faq.png'

const FAQPage = () => {

  return (
    <div className="faq-root">
      <h2>FAQs and Information</h2>
      <img className="faq-img" alt="Kelly Aiken" src={faq} />
      <p>
        All orders are packaged by Kelly in the studio with recycled materials, handled with care. When you purchase an original piece of artwork, your satisfaction and excitement is extremely important. Please, click <a href="mailto:copeleydesigns@gmail.com?subject=CD Art Items of Purchase"><u>here</u></a> to reach out with any questions regarding your purchase.
      </p>
      <p>
        <span>Can I return the artwork that I purchased?</span><br />
        All sales on original art are final. I do my best to make my listings as clear and informative as possible. If you need more photos, extra information or have any questions about the piece , I encourage you to contact me before purchase.
      </p>
      <p>
        <span>How do I request a comissioned work?</span><br />
        Please contact artist for work that is in line with her creative vision and portfolio of work. The artist can not copy work from any other artist, and will not accept client commission if it is not in line with CD Art. Mediums include acrylic, watercolor, resin, and metal. Gold leaf/Silver gilded works have priority. No portraits, no children's themed work.
      </p>
      <p>
        <span>My artwork was damaged upon arrival. Help!</span><br />
        I always make it a priority to handle and package each order carefully and use quality packaging materials to keep artwork safe during transit. That being said, I am not liable for failed delivery by USPS, incorrect addresses, or mishandling by the shipping service. After purchasing artwork, you will receive a confirmation email. Please check that the address given to me is correct. Any purchased artwork that is returned to me due to an incorrect address by postal service, I will happily resend after additional shipping cost is invoiced to you and paid. If the address is correct and you are concerned that the package has been lost or stolen, please contact me for the tracking number, we can contact the postal service. If you would like to purchase additional shipping insurance, that can be arranged. If the package is damaged, please send photos on arrival, and plan to return after contacting CD Art. We will discuss art replacement one-on-one.
      </p>
      <p>
        <span>Do you ship internationally?</span><br />
        Yes, I do! If you are shopping outside of the U.S, shipping can be arranged. Please email me directly at <a href="mailto:copeleydesigns@gmail.com">copeleydesigns@gmail.com</a>.
      </p>
      <p>
        <span>Do you travel for wall murals?</span><br />
        Yes, please contact <a href="mailto:copeleydesigns@gmail.com">copeleydesigns@gmail.com</a> to discuss location, travel fee, and lodging fees. I have done murals in Charlottesville, V.A., Washington D.C., Charlotte, N.C., and Nashville, T.N. I specialize in interior wall murals with abstract and gold. Please do not contact for children's mural walls unless they are abstract art, and no portrait work please. Thank you!
      </p>
    </div>
  );
}

export default FAQPage;
