import './ComingSoonPage.css';
import art from '../assets/art.png'
import mural from '../assets/mural.png'
import bracelets from '../assets/bracelets.png'
import prints from '../assets/prints.png'
import classes from '../assets/classes.png'
import DisplayImage from '../components/DisplayImage';
import NavMenu from '../components/NavMenu';
import Footer from '../components/Footer';
import useEventbrite from 'react-eventbrite-popup-checkout';
import { useCallback } from 'react';

const ComingSoonPage = () => {

  const handleOrderCompleted = useCallback(() => {
    console.log('Order was completed successfully');
  }, []);

  const eventbrite = useEventbrite({
    eventId: '1008047215357',
    // modal: true,
    iFrameAutoAdapt: 100,
    onOrderComplete: handleOrderCompleted,
  });

  return (
    <div className="soon-root">
      <div className="soon-cover">
        <div className="soon-text-group">
          <br />
          <h1 className="soon-text">See You Soon!</h1>
          <p className="soon-text">We are currently transitioning between web hosting providers, but will be back again shortly!</p>
          <br /><br /><br />
        </div>
        {eventbrite && <div className="soon-event" id={eventbrite.id} />}
      </div>
      <div className="soon-box">
        <DisplayImage text="Original Art" imgSrc={art} />
        <DisplayImage text="Mural Wall Tutoral" imgSrc={mural} />
        <DisplayImage text="Original Art Bracelets" imgSrc={bracelets} />
        <DisplayImage text="Prints, Coasters, and Stickers" imgSrc={prints} />
        <DisplayImage text="Gilding Gold Art Classes" imgSrc={classes} />
      </div>
    </div>
  );
}

export default ComingSoonPage;
