import './PageRoot.css';
import NavMenu from '../components/NavMenu';
import Footer from '../components/Footer';
import { Link, Outlet } from 'react-router-dom';

const menues = {
  "Shop": {
    // _: '/shop',
    // "Mural Wall Tutoral": '/shop/mural-wall-tutoral',
    // "Original Abstracts with Gold": '/shop/gold-leaf-art',
    // "Original Art Bracelets": '/shop/bracelets',
    // "Prints": '/shop/prints',
    // "Ornaments": '/shop/ornaments'
  },
  "Portfolio": {
    // _: '/portfolio'
  },
  "More": {
    // "In the Media": '/blog',
    // "Art Classes": '/classes',
    // "Tutorial Videos (Password Locked)": '/videos'
  }
}

const PageRoot = () => {

  return (
    <div className="full-container">
      <div className="page-container">
        <Link to={'/'}>
          <div className="header-text">
            <h1 className="title">Copeley Designs Art</h1>
            <span className="subtitle">Original Gold Leaf Art<br />by Kelly Aiken</span>
          </div>
        </Link>
        <NavMenu menues={menues} />
        <div className="content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default PageRoot;
