import './Footer.css';
import xSocial from '../assets/x-social.svg'
import facebook from '../assets/facebook.svg'
import pinterest from '../assets/pinterest.svg'
import linkedin from '../assets/linkedin.svg'
import tiktok from '../assets/tiktok.svg'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <div className="social-icons">
        <a href="https://www.twitter.com/copeleydesigns"><img className="icon" src={xSocial} alt="Twitter" target="_blank" /></a>
        <a href="https://www.facebook.com/copeleydesigns"><img className="icon" src={facebook} alt="Facebook" target="_blank" /></a>
        <a href="https://www.pinterest.com/copeley_designs_art"><img className="icon" src={pinterest} alt="Pinterest" target="_blank" /></a>
        <a href="https://www.linkedin.com/in/kelly-aiken-copeley-designs"><img className="icon" src={linkedin} alt="LinkedIn" target="_blank" /></a>
        <a href="https://www.tiktok.com/@copeleydesigns"><img className="icon" src={tiktok} alt="TikTok" target="_blank" /></a>
      </div>
      <div className="footer-links">
        <Link to="/contact">Contact Us</Link>
        <Link to="/terms">Terms of Use</Link>
        <Link to="/faqs">FAQS and Information</Link>
      </div>
      <span>All images and site content &copy;2024 Copeley Designs Art LLC</span>
    </div>
  );
}

export default Footer;
