import './DisplayImage.css';

const DisplayImage = ({ imgSrc, text, url }) => {
  return (
    <div className="display-container">
      <div className="display-font">
        <img className="display-image" src={imgSrc} alt={text} />
        <div className="display-text">{text}</div>
      </div>
    </div>
  );
}

export default DisplayImage;
