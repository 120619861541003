import './PortfolioPage.css';
import faq from '../assets/faq.png'
import { useCallback, useMemo, useState } from 'react';

// const shape = [ // ARRAY = ROW
//   [1, 1, 1], // NUMBER = # OF IMAGES IN COLUMN
//   [2, 1, [2, 1]], // SUBARRAY + NUMBER = # IN ROW IN COLUMN,
//   [[1, 2], 1, 1],
//   [[2, 1], 1],
//   [2, [1, 2], 2, 2],
//   [2, [2, 1], 2],
//   [1, [2, 1]]
// ]

const images = [
  {
    url: faq,
    row: 1,
    column: 1,
    title: 'Client Cocktail Room Abstract wall, copyright Copeley Designs Art',
    description: 'Client commissioned a bold luxury cocktail room, with a classy calm feel. Contact copeleydesigns@gmail.com for your own personal abstract wall art. '
  },
  {
    url: faq,
    row: 1,
    column: 2,
    title: 'Artwork, sold, October Sky Copyright Copeley Designs Art',
    description: 'SOLD, studio works in October 2023'
  },
  {
    url: faq,
    row: 1,
    column: 3,
    title: 'The Winnifred Mural, CLT Skyline',
    description: 'Apartment complex block CLT Skyline mural with gilded gold leafing hornets and panther. Mural Art by Kelly Aiken.'
  },
  {
    url: faq,
    row: 2,
    column: 1,
    pos: 1,
    title: 'Abstract Wall Art',
    description: 'DESCRIPTION HERE'
  },
  {
    url: faq,
    row: 2,
    column: 1,
    pos: 2,
    title: 'Half wall Abstract Mural art Copyright CD Art',
    description: 'Commissioned basement wall art, half wall with bold luxury feel. Have ideas for your own wall? email copeleydesigns@gmail.com for quotes.'
  },
  {
    url: faq,
    row: 2,
    column: 2,
    title: 'The Cocktailery in Atherton Mill Mall Charlotte',
    description: 'Working alongside Little King Art, completing this beautiful "whimsical cocktail ingredient" wall in Charlotte, NC. Contact copeleydesigns@gmail.com for your own mural design consult.'
  },
  {
    url: faq,
    row: 2,
    column: 3,
    subrow: 1,
    pos: 1,
    title: 'Minted x West Elm winner- "Treasure Island" by Kelly Aiken',
    description: 'Prints Available on minted.com and westelm.com, original art RETIRED, Abstract by Kelly Aiken of Copeley Designs Art.'
  },
  {
    url: faq,
    row: 2,
    column: 3,
    subrow: 1,
    pos: 2,
    title: '"Ever Changing Emotions" by Kelly Aiken 2022',
    description: "Solo Show at The Artisan's Palate, May 2022 in Charlotte NC. SOLD at solo show."
  },
  {
    url: faq,
    row: 2,
    column: 3,
    subrow: 2,
    title: 'Bohemian Style Bold Decor Room',
    description: 'DESCRIPTION HERE'
  },
  {
    url: faq,
    row: 3,
    column: 1,
    subrow: 1,
    pos: 1,
    title: 'All the Feels by Kelly Aiken',
    description: 'SOLD, by Art House Charlotte, see more work by Kelly Aiken at Art House Charlotte in South end area.'
  },
  {
    url: faq,
    row: 3,
    column: 1,
    subrow: 2,
    title: 'New York City Skyline Commission',
    description: 'Commission, SOLD'
  },
  {
    url: faq,
    row: 3,
    column: 1,
    subrow: 2,
    pos: 2,
    title: 'Tidal Wave by Kelly Aiken',
    description: 'SOLD. Gold Leaf and Acrylic on paperworks, released in spring collection 2020.'
  },
  {
    url: faq,
    row: 3,
    column: 2,
    title: 'Ombre Beach Painting by Kelly Aiken, SOLD',
    description: 'SOLD from Abstracted Summer Collection by Kelly Aiken, 2020. Copyright Copeley Designs Art'
  },
  {
    url: faq,
    row: 3,
    column: 3,
    title: 'Kitchen Design by Kelly Aiken',
    description: 'COPYRIGHT COPELEY DESIGNS ART 2023, hand painted wall murals, gilded gold walls in kitchen space'
  },
  {
    url: faq,
    row: 4,
    column: 1,
    subrow: 1,
    pos: 1,
    title: 'Solo Show, "Changing the Scenery", 2022 Available',
    description: `Solo Show at The Artisan's Palate. May 2022. Large Art "Changing the Scenery" acrylic, pigment, and resin with gold leaf.`
  },
  {
    url: faq,
    row: 4,
    column: 1,
    subrow: 1,
    pos: 2,
    title: 'Stolen Heart by Kelly Aiken of Copeley Designs Art',
    description: 'SOLD through Art House Charlotte Gallery in Charlotte NC. Art by Kelly Aiken, copeleydesigns.com'
  },
  {
    url: faq,
    row: 4,
    column: 1,
    subrow: 2,
    title: 'Rented and Staged work in Huntersville, NC by Kelly Aiken',
    description: 'Rent or stage with Copeley Designs Art! These "Golden Tundra" diptych works are available for purchase, contact copeleydesigns@gmail.com'
  },
  {
    url: faq,
    row: 4,
    column: 2,
    title: 'Vibing the Morning Weather by Kelly Aiken',
    description: 'SOLD.'
  },
  {
    url: faq,
    row: 5,
    column: 1,
    title: 'Golden Haze by Kelly Aiken',
    description: 'Commissioned piece, SOLD'
  },
  {
    url: faq,
    row: 5,
    column: 1,
    pos: 2,
    title: 'Gold Leaf Block Wall, Robinson Ortho',
    description: 'Wall designed by Kim  McMillian, Charlotte Interior designer. Painting and Gold Leaf work completed by Kelly Aiken. Artist executed designers look with a gold leaf and neutral mural in an orthodontist space.'
  },
  {
    url: faq,
    row: 5,
    column: 2,
    subrow: 1,
    pos: 1,
    title: 'Whirlwind by Kelly Aiken',
    description: 'Available, email copeleydesigns@gmail.com'
  },
  {
    url: faq,
    row: 5,
    column: 2,
    subrow: 2,
    title: 'Charlotte Skyline wall mural, copyright COPELEY DESIGNS ART',
    description: 'COPELEY DESIGNS ART, copyright wall art, hand painted hand blended wall art'
  },
  {
    url: faq,
    row: 5,
    column: 2,
    subrow: 2,
    pos: 2,
    title: 'Mural Walls, Drip design, artist Kelly Aiken',
    description: 'Abstract Art murals by Kelly Aiken, tutorial on Copeley Designs Shop page, learn all the tips and tricks to making your own Abstract wall with gold leaf. Original statement accent walls for any interior space.'
  },
  {
    url: faq,
    row: 5,
    column: 3,
    title: 'Gold Leaf Wall',
    description: 'As seen in Wall Mural Tutorial DESCRIPTION HERE'
  },
  {
    url: faq,
    row: 5,
    column: 3,
    pos: 2,
    title: 'Charlotte Skyline Commission',
    description: 'Charlotte, NC Commission, SOLD'
  },
  {
    url: faq,
    row: 5,
    column: 4,
    title: 'Mural Accent Wall Selfie by Kelly Aiken',
    description: 'Book your wall art for 2021 now! Artist Kelly Aiken comes into your home for beautiful accent walls with gold metal leafing, murals to bring your space glam.'
  },
  {
    url: faq,
    row: 5,
    column: 4,
    pos: 2,
    title: 'Light within the Shadows',
    description: "Chosen Women's Apparel and Fine Art"
  },
  {
    url: faq,
    row: 6,
    column: 1,
    title: 'Commissioned Large Abstract by Kelly Aiken',
    description: 'SOLD.'
  },
  {
    url: faq,
    row: 6,
    column: 1,
    pos: 2,
    title: 'Dining room Client Wall Mural by Kelly Aiken',
    description: 'Copyright Copeley Designs Art, wall mural statement designs, boho style, jungalow style by Kelly Aiken'
  },
  {
    url: faq,
    row: 6,
    column: 2,
    subrow: 1,
    pos: 1,
    title: 'Charlotte NC Skyline by Artist Kelly Aiken. Commissioned',
    description: 'SOLD. Skyline Artist Kelly Aiken, Panthers colors, with silver leafing skyline art, custom skyline city art for any space, man cave includedcopeleydesigns.com'
  },
  {
    url: faq,
    row: 6,
    column: 2,
    subrow: 1,
    pos: 2,
    title: 'That September Day by Kelly Aiken of Copeley Designs Art',
    description: 'Original SOLD, from The "Acceptance Series" 2019. Need a print? See Shop online for available gold leaf prints, hand gilded by Kelly Aiken.'
  },
  {
    url: faq,
    row: 6,
    column: 2,
    subrow: 2,
    pos: 1,
    title: 'Gold Leaf Wall Art Mural by Artist Kelly, Commissioned 2021',
    description: 'Gold Leaf mural walls, by Kelly Aiken. Add shine and texture to your living space with original art walls. copeleydesigns.com'
  },
  {
    url: faq,
    row: 6,
    column: 3,
    title: '"Distorted in Gold", canvas art with resin and gold reflective chrome pigment.',
    description: `This piece was featured at "Distorting Reflections" Show at the Artisan's Palette in CLT, NC. Exploration of dermatillomania and mental health awareness.`
  },
  {
    url: faq,
    row: 6,
    column: 3,
    pos: 2,
    title: 'Gymnastics Center Abstract TUCK 37 mural art',
    description: 'Abstract and Gold Leaf wall art by Kelly Aiken of Copeley Designs Art. Art for commercial business spaces, located in SC.'
  },
  {
    url: faq,
    row: 7,
    column: 1,
    title: 'Winnifred Outdoor Mural',
    description: 'DESCRIPTION HERE'
  },
  {
    url: faq,
    row: 7,
    column: 2,
    subrow: 1,
    pos: 1,
    title: 'Wall Mural ADD TITLE',
    description: 'Wall Mural, teaming with Play Wild Child- interiors for playrooms! Bold linework in boy and girl playroom space.'
  },
  {
    url: faq,
    row: 7,
    column: 2,
    subrow: 1,
    pos: 2,
    title: 'Commissioned Dog Artwork by Kelly Aiken',
    description: 'Whimsical dog commissions, RIP sweet dogs! Watercolor, Acrylic, Gilded Gold, and micron pen details.'
  },
  {
    url: faq,
    row: 7,
    column: 2,
    subrow: 2,
    pos: 1,
    title: 'Orthodontist block wall art by Kelly Aiken, copyright 2023',
    description: 'Copyright 2023 Copeley Designs, Colorful wall art block modern style, Fort Mill location'
  },
]

const shape = images.reduce((p, c, index) => {
  p[(c.row || 1) - 1] = p[(c.row || 1) - 1] || []
  if (c.subrow) {
    p[(c.row || 1) - 1][(c.column || 1) - 1] = p[(c.row || 1) - 1][(c.column || 1) - 1] || []
    p[(c.row || 1) - 1][(c.column || 1) - 1][c.subrow - 1] = p[(c.row || 1) - 1][(c.column || 1) - 1][c.subrow - 1] || { images: [] }
    p[(c.row || 1) - 1][(c.column || 1) - 1][c.subrow - 1].images[(c.pos || 1) - 1] = { url: c.url, index }
  } else {
    p[(c.row || 1) - 1][(c.column || 1) - 1] = p[(c.row || 1) - 1][(c.column || 1) - 1] || { images: [] }
    p[(c.row || 1) - 1][(c.column || 1) - 1].images[(c.pos || 1) - 1] = { url: c.url, index }
  }
  return p
}, [])

const PortfolioImage = ({ img, index, onClick }) => {

  const handleClick = useCallback(() => {
    console.log('GOT CLICK', index)
    onClick(index)
  }, [index, onClick])

  return (
    <div className="portfolio-cell" onClick={handleClick}><img className="portfolio-image" src={img} /></div>
  );

}

const PortfolioPage = () => {
  const [index, setIndex] = useState(null)

  const handleClose = useCallback(() => {
    setIndex(null)
  }, [])

  const handleSelect = useCallback((idx) => {
    setIndex(idx)
  }, [])

  const handleBack = useCallback(() => {
    setIndex(prev => Math.max(prev - 1, 0))
  }, [])

  const handleForward = useCallback(() => {
    setIndex(prev => Math.min(prev + 1, images.length - 1))
  }, [images])

  const current = useMemo(() => {
    return index !== null ? images[index] : null
  }, [index, images])

  return (<>
    <div className="portfolio-root">
      <h2>Copeley Designs Portfolio</h2>
      <span>Commissioned Murals, Retired Works, and Sold Works<br />2015-2022</span>
      <div className="portfolio-block">
        {shape.map(row => (<div className="portfolio-row">
          {row.map(column => {
            return Array.isArray(column) ? <div className="portfolio-column">
              {column.map(subrow =>
                <div className="portfolio-subrow">
                  {subrow.images.map((image) => <PortfolioImage img={image.url} index={image.index} onClick={handleSelect} />)}
                </div>
              )}
            </div> : <div className="portfolio-column">
              {column.images.map((image) => <PortfolioImage img={image.url} index={image.index} onClick={handleSelect} />)}
            </div>
          })}
        </div>))}
      </div>
    </div>
    {current && <div className="portfolio-full">
      <button className="portfolio-full-close" onClick={handleClose}>&#215;</button>
      <div className="portfolio-full-carosuel">
        <div className="portfolio-full-right">
          <button className="portfolio-full-button" onClick={handleBack} disabled={!index}>&lsaquo;</button>
        </div>
        <div className="portfolio-full-center">
          <img src={faq} alt="Full Photo" className="portfolio-full-photo" />
        </div>
        <div className="portfolio-full-right">
          <button className="portfolio-full-button" onClick={handleForward} disabled={index === images.length - 1}>&rsaquo;</button>
        </div>
      </div>
      <div className="portfolio-full-text">
        <div className="portfolio-full-text-block">
          <p className="portfolio-full-text-title">{current.title}</p>
          <p className="portfolio-full-text-desc">{current.description}</p>
        </div>
      </div>
    </div>}
  </>);
}

export default PortfolioPage;
